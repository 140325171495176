<template>
    <el-container>
        <el-aside width="300px">
            <el-container>
                <el-header>
                    <el-input placeholder="输入关键字进行过滤" v-model="FilterText" clearable></el-input>
                </el-header>
                <el-main class="nopadding">
                    <el-tree ref="menu" class="menu" node-key="groupId" :data="ListTree" :props="Props" draggable highlight-current :expand-on-click-node="false" check-strictly show-checkbox :filter-node-method="menuFilterNode" @node-click="menuClick">
                        <template #default="{node, data}">
                            <span class="custom-tree-node">
                                <span class="label"> {{ node.label }}</span>
                                <span class="do">
                                    <i class="el-icon-plus" @click.stop="add(node, data)"></i>
                                </span>
                            </span>
                        </template>
                    </el-tree>
                </el-main>
                <el-footer style="height:51px;">
                    <el-button type="primary" size="mini" icon="el-icon-plus" @click="GroupsIni()">初始化设备组</el-button>
                    <el-button type="danger" size="mini" plain icon="el-icon-delete" @click="DelGroups"></el-button>
                </el-footer>
            </el-container>
        </el-aside>
        <el-container>
            <el-main class="nopadding" style="padding:20px;">
                <el-row>
                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-button type="success" icon="el-icon-edit" size="medium" @click="showDialog">新增</el-button>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-table :data="tableData" style="width: 100%" :default-sort="{prop: 'deviceName', order: 'descending'}">
                    <el-table-column header-align="center" align="center" prop="deviceSerial" label="设备序列号">
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="deviceModel" label="设备型号">
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="deviceName" label="设备名称">
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="deviceStatus" label="设备状态">
                        <template #default="scope">
                            <label>{{scope.row.deviceStatus==1?"在线":"离线"}}</label>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="deviceVersion" label="设备固件版本号">
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="操作">
                        <template #default="scope">
                            <el-button size="mini" @click="namedialogVisible=true;deviceName=scope.row.deviceName;deviceSerial=scope.row.deviceSerial">修改名称</el-button>
                            <el-popconfirm confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red" title="确定删除吗？" @confirm="btnDelete(scope.row)">
                                <template #reference>
                                    <el-button type="danger" size="mini">删除</el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </el-main>
            <el-dialog v-model="dialogVisible" title="设备新增" width="35%" :before-close="handleClose">
                <el-form ref="form" :model="form" status-icon label-width="120px" :rules="rules">
                    <el-form-item label="所属组织">
                        <el-input v-model="groupInfo.groupName" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="序列号" prop="deviceSerial">
                        <el-input v-model="form.deviceSerial"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码" prop="validateCode">
                        <el-input v-model="form.validateCode"></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">关闭</el-button>
                        <el-button type="primary" @click="btnAdd">确定</el-button>
                    </span>
                </template>
            </el-dialog>
            <el-dialog v-model="namedialogVisible" title="修改名称" width="35%" :before-close="handleClose">
                <el-form ref="form2" status-icon label-width="120px">
                    <el-form-item label="设备名称">
                        <el-input v-model="deviceName"></el-input>
                    </el-form-item>

                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="namedialogVisible = false">关闭</el-button>
                        <el-button type="primary" @click="btnUpdate">确定</el-button>
                    </span>
                </template>
            </el-dialog>
        </el-container>
    </el-container>
</template>

<script>
export default {
    name: "device",
    data() {
        return {
            FilterText: "", //搜索关键字
            tableData: [], //表格数据
            groupInfo: null,
            dialogVisible: false,
            namedialogVisible: false,
            deviceName: "",
            deviceSerial: "",
            Props: {
                label: (data) => {
                    return data.groupName;
                },
                children: (data) => {
                    return data.Children;
                },
            },
            ListTree: [],
            form: {
                deviceSerial: "",
                validateCode: "",
                groupNo: 0,
            },
            //输入验证
            rules: {
                deviceSerial: [{ required: true, message: "序列号不能为空" }],
                validateCode: [{ required: true, message: "验证码不能为空" }],
            },
        };
    },
    watch: {
        FilterText(val) {
            this.$refs.menu.filter(val);
        },
    },
    mounted() {
        this.GroupsListAll();
    },
    methods: {
        GroupsListAll() {
            this.$API.HK.GroupsListAll.get().then((res) => {
                console.log(res);
                var trees = this.TreeInit(res.data);
                this.ListTree = trees;
            });
        },
        //显示新增设备输入框
        showDialog() {
            if (this.groupInfo == null) {
                this.$message.error("请选择组织");
                return;
            }
            this.dialogVisible = true;
        },
        //新增设备
        btnAdd() {
            var that = this;
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.form.groupNo = this.groupInfo.groupNo;
                    that.$API.HK.DevicesCreate.post(that.form).then((res) => {
                        console.log(res);
                        if (res.Code == 204) {
                            that.$notify({
                                title: "成功",
                                message: "操作成功！",
                                type: "success",
                            });
                            that.$API.HK.DevicesList.get(
                                that.form.groupNo
                            ).then((res) => {
                                this.tableData = res.data.rows;
                            });
                        } else {
                            that.$notify({
                                title: "失败",
                                message: res.message,
                                type: "error",
                            });
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        //修改设备名称
        btnUpdate() {
            if (
                this.deviceName == null ||
                this.deviceName == undefined ||
                this.deviceName == ""
            ) {
                this.$notify({
                    title: "失败",
                    message: "请输入设备名称",
                    type: "error",
                });
                return false;
            }
            var json = {
                deviceName: this.deviceName,
                deviceSerial: this.deviceSerial,
            };
            this.$API.HK.DevicesUpdate.post(json).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.$notify({
                        title: "成功",
                        message: "操作成功！",
                        type: "success",
                    });
                    this.$API.HK.DevicesList.get(this.groupInfo.groupNo).then(
                        (res) => {
                            this.tableData = res.data.rows;
                        }
                    );
                } else {
                    this.$notify({
                        title: "失败",
                        message: res.message,
                        type: "error",
                    });
                }
            });
        },
        //删除设备
        btnDelete(obj) {
            console.log(obj);
            this.$API.HK.DevicesDelete.post(obj.deviceSerial).then((res) => {
                if (res.code == 200) {
                    this.$notify({
                        title: "成功",
                        message: "操作成功！",
                        type: "success",
                    });
                    this.$API.HK.DevicesList.get(this.groupInfo.groupNo).then(
                        (res) => {
                            this.tableData = res.data.rows;
                        }
                    );
                } else {
                    this.$notify({
                        title: "失败",
                        message: res.message,
                        type: "error",
                    });
                }
            });
        },

        //树过滤
        menuFilterNode(value, data) {
            if (!value) return true;
            var targetText = data.groupName;
            return targetText.indexOf(value) !== -1;
        },
        GroupsIni() {
            console.log("点击");
            this.$API.HK.GroupsIni.post().then((res) => {
                //console.log(res);
                this.GroupsListAll();
            });
        },
        menuClick(data, node) {
            console.log("data=>", data);
            console.log("node=>", node);
            this.groupInfo = data;
            this.$API.HK.DevicesList.get(data.groupNo).then((res) => {
                console.log(res);
                this.tableData = res.data.rows;
            });
        },
        //删除菜单
        DelGroups() {
            var that = this;
            var CheckedNodes = that.$refs.menu.getCheckedNodes();
            if (CheckedNodes.length == 0) {
                that.$message.warning("请选择需要删除的项");
                return false;
            }
            var ids = [];
            CheckedNodes.forEach((item) => {
                if (item.groupNo <= 0) that.$refs.menu.remove(item);
                else ids.push(item.groupNo);
            });
            if (ids.length > 0)
                that.$API.HK.GroupsDelete.post(ids).then((res) => {
                    console.log("res", res);
                    if (res.Code == 204) {
                        that.GroupsListAll();
                    } else
                        that.$notify({
                            title: "失败",
                            message: res.Message,
                            type: "error",
                        });
                });
        },
        TreeInit(obj, parentId = null) {
            var that = this;
            if (obj == null || obj == undefined) return null;
            var result = obj.filter(function (value, index) {
                return value.parentId == parentId;
            });
            var nextResult = obj.filter(function (value, index) {
                return value.parentId != parentId;
            });
            if (result == null || result == undefined || result.length == 0)
                return null;
            result.forEach((element) => {
                element.Children = that.TreeInit(nextResult, element.groupId);
            });
            return result;
        },
    },
};
</script>


<style scoped>
.custom-tree-node {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 24px;
    height: 100%;
}
.custom-tree-node .label {
    display: flex;
    align-items: center;
    height: 100%;
}
.custom-tree-node .label .el-tag {
    margin-left: 5px;
}
.custom-tree-node .do {
    display: none;
}
.custom-tree-node .do i {
    margin-left: 5px;
    color: #999;
    padding: 5px;
}
.custom-tree-node .do i:hover {
    color: #333;
}

.custom-tree-node:hover .do {
    display: inline-block;
}
.icon {
    padding-right: 10px;
}
</style>
